import React from "react";
import "./Header.css";
import Imagenes from "../assets/Imagenes";
import { Link } from "react-router-dom";
const handleClickhd = () => {
  window.open("https://wa.me/+57320672882", "_blank");
};
const handleClickcut = () => {
  window.open("https://cut.org.co/", "_blank");
};
const handleClickie = () => {
  window.open("https://www.ei-ie-al.org/", "_blank");
};
const Header = () => {
  return (
    <header>
      <div class="logo">
        <Link to={"/"}>
          <div className="navbar-brand">
            <img src={Imagenes.aspuch} alt="" />
            <span>ASPUCHOCO</span>
          </div>
        </Link>
      </div>
      <nav>
        <Link to="/" className="nav-link">
          Inicio <span class="sr-only">(current)</span>
        </Link>
        <Link to="/acerca" className="nav-link">
          Acerca de
        </Link>
        <Link to="/normatividad" className="nav-link">
          Normatividad
        </Link>
        <Link to="/normatividad/sentencias" className="nav-link">
          Sentencias
        </Link>
        <Link to="/blog" className="nav-link">
          Blog
        </Link>
        <Link to="/contacto" className="nav-link">
          Contacto
        </Link>
      </nav>{" "}
      <img
          src={Imagenes.cut}
          alt=""
          width="50px"
          onClick={handleClickcut}
        />
                <img
          src={Imagenes.ie}
          alt=""
          width="50px"
          onClick={handleClickie}
        />
      <Link to="">
        <img
          src={Imagenes.whatsapp}
          alt=""
          width="50px"
          onClick={handleClickhd}
        />
      </Link>
      
    </header>
  );
};

export default Header;
