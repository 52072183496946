import React, { Fragment } from "react";
import Header from "../Header";
import Contacto from "./Contacto";

const ContactoPage = () => {
  return (
    <Fragment>
      <Header />
      <Contacto />
    </Fragment>
  );
};

export default ContactoPage;
