import React from "react";
import { Link } from "react-router-dom";
import Imagenes from "../../assets/Imagenes";

const Slinder = () => {
  return (
    <div className="hero_area">
      <section className=" slider_section position-relative">
        <div className="slider_bg-container"></div>
        <div className="slider-container">
          <div className="detail-box">
            <h3>ASOCIACIÓN SINDICAL DE PROFESORES UNIVERSITARIOS</h3>
            <p>
              Defender dentro del marco jurídico laboral, los principios de
              concertación laboral, diálogo respetuoso y amigable composición,
              los derechos laborales, prestacionales, políticos y de
              participación democrática de los docentes de la Universidad
              Tecnológica del Chocó.
            </p>
            <div>
              <Link to="/contacto" className="slider-link">
                CONTACTO
              </Link>
            </div>
          </div>
          <div className="img-box">
            <div
              id="carouselExampleControls"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={Imagenes.slinder} alt="" />
                </div>
                <div className="carousel-item">
                  <img src={Imagenes.slinder} alt="" />
                </div>
                <div className="carousel-item">
                  <img src={Imagenes.slinder} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slinder;
