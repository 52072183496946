import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";

function App() {
  const [post, setPost] = useState([]);
  const [recuperado, setRecuperado] = useState(false);

  function mostrarTabla() {
    return (
      <section className="news_section layout_padding">
        <div className="container">
          <div className="d-flex flex-column align-items-end">
            <div className="custom_heading-container">
              <hr />
              <h2>Ultimas noticias</h2>
            </div>
            <p>
              Lee las ultima actividades de la ASOCIACIÓN SINDICAL DE PROFESORES
              UNIVERSITARIOS.
            </p>
          </div>
          <div className="row">
            {post.registros.map((publicaciones) => {
              const nombreImagen = publicaciones.titulo;
              const nuevoNombreImage = nombreImagen.replace(/\s/g, "-");
              return (
                <div className="col-md-4">
                  <div className="box">
                    {publicaciones.thumb !== null ? (
                      <div className="img-box">
                        <img src={publicaciones.thumb} alt="" />
                      </div>
                    ) : null}

                    <div className="action-box">
                      <div className="action">
                        <Link to="blog/:id">
                          <img src="images/like.png" alt="" />
                        </Link>
                        <Link to="blog/:id">
                          <img src="images/comment.png" alt="" />
                        </Link>
                        <Link to="blog/:id">
                          <img src="images/share.png" alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="detail-box">
                      <h4>{publicaciones.titulo}</h4>
                      <p className="text-align-justify">
                        {publicaciones.extracto}
                      </p>
                      {publicaciones.anexo !== null ? (
                        <div>
                          <Link
                            to={`circular/${publicaciones.id}/${nuevoNombreImage}`}
                            className="btn btn-secondary"
                          >
                            Leer mas...
                          </Link>
                        </div>
                      ) : publicaciones.ContenidoImgs !== null ? (
                        <div>
                          <Link
                            to={`evento/${publicaciones.id}/${nuevoNombreImage}`}
                            className="btn btn-danger"
                          >
                            Leer mas...
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <Link
                            to={`blog/${publicaciones.id}/${nuevoNombreImage}`}
                            className="btn btn-primary"
                          >
                            Leer mas...
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }

  useEffect(() => {
    fetch("https://service.aspuchoco.org/post_inicio.php")
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        setPost(post);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <section className="news_section layout_padding">
        <div className="container">
          <div className="d-flex flex-column align-items-end">
            <div className="custom_heading-container">
              <hr />
              <h2>Ultimas noticias</h2>
            </div>
            <p>
              - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
              - - - - - - - - - - -
            </p>
          </div>
          <div className="row">
            <Fragment>
              <div className="col-md-4">
                <div className="box">
                  <Skeleton />
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <Skeleton />
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <Skeleton />
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </section>
    );
}

export default App;
