import React, { Fragment } from 'react'
import Header from '../Header';
import Evento from './Evento';

const EventoPage = () => {
    return ( 
        <Fragment>
            <Header/>
            <Evento/>
        </Fragment>
     );
}
 
export default EventoPage;