import React, { Fragment } from "react";
import Header from "../Header";
import Estatuto from "./Estatuto";

const EstatutoPage = () => {
  return (
    <Fragment>
      <Header />
      <Estatuto />
    </Fragment>
  );
};

export default EstatutoPage;
