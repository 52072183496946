import React from "react";
import Imagenes from "../assets/Imagenes";

const Social = () => {
  const handleClicktw = () => {
    window.open("https://twitter.com/ASPU_Colombia", "_blank");
  };
  const handleClickin = () => {
    window.open("https://www.instagram.com/aspu_colombia/", "_blank");
  };
  const handleClickli = () => {
    window.open("https://www.youtube.com/@aspucolombia4540", "_blank");
  };
  const handleClickfb = () => {
    window.open("https://web.facebook.com/ASPU.Colombia", "_blank");
  };
  return (
    <section className="info_section layout_padding">
      <div className="container">
        <div className="info_social">
          <div>
            <p href="">
              <img onClick={handleClickfb} src={Imagenes.fb} alt="" />
            </p>
          </div>
          <div>
            <p href="">
              <img onClick={handleClicktw} src={Imagenes.tw} alt="" />
            </p>
          </div>
          <div>
            <p href="">
              <img onClick={handleClickin} src={Imagenes.ins} alt="" />
            </p>
          </div>
          <div>
            <p href="">
              <img onClick={handleClickli} src={Imagenes.you} alt="" />
            </p>
          </div>
        </div>
        <div></div>
      </div>
    </section>
  );
};

export default Social;
