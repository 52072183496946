import { Fragment } from "react";
import Footer from "./inc/Footer";
import Social from "./inc/Social";
import Router from "./Router";

function App() {
  return (
    <Fragment>
      <Router />
      <Social />
      <Footer />
    </Fragment>
  );
}

export default App;
