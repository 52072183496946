import React from "react";
import LoaderBlog from "./LoaderBlog";
import "./style.css";

import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

function Single() {
  const [post, setPost] = useState([]);
  const [recuperado, setRecuperado] = useState(false);

  const params = useParams();
  function mostrarTabla() {
    return (
      <section class="p-top-80 p-bottom-80">
        <center>
          {post.registros.map((publicaciones) => {
            return (
              <div class="col-sm-8">
                <div class="postSingle">
                  <div class="postMedia">
                    <img alt="" src={publicaciones.thumb} width="80%" />

                    <div class="postMeta clearfix">
                      <div class="postMeta-info">
                        <span class="metaAuthor">
                          <i class="fa fa-user"></i>{" "}
                          <p>{publicaciones.nombre}</p>
                        </span>

                        <div class="postMeta-date">
                          <span class="metaDate">
                            <i class="fa fa-calendar"></i>{" "}
                            <p>{publicaciones.fecha}</p>
                          </span>
                        </div>
                      </div>

                      <div class="postTitle">
                        <h1>{publicaciones.titulo}</h1>
                        <div class="divider-small"></div>
                      </div>

                      <p>{publicaciones.texto}</p>
                    </div>
                    <Link to="/blog" className="btn btn-secondary">
                      Ver mas post
                    </Link>
                    <div>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </center>
      </section>
    );
  }

  useEffect(() => {
    fetch(`https://service.aspuchoco.org/single.php?id=${params.id}`)
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        setPost(post);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <center>
        <div class="col-sm-8">
          <div class="postSingle">
            <div class="postMedia">
              <div class="postMeta clearfix">
                <div class="postMeta-info">
                  <span class="metaAuthor">
                    <i class="fa fa-user"></i>
                  </span>

                  <div class="postMeta-date">
                    <span class="metaDate">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
                <div class="postTitle">
                  <div class="divider-small"></div>
                </div>
                <LoaderBlog />;
              </div>
            </div>
          </div>
        </div>
      </center>
    );
}

export default Single;
