import React, { Fragment } from "react";
import BotonFlotante from "../components/BotonFlotante";
const Estatuto = () => {
  return (
    <Fragment>
      <BotonFlotante />
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <object
          data={require("./estatuto.pdf")}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <br />
          <a
            href={require("./estatuto.pdf")}
            id="enlaceDescargarPdf"
            download="estatuto.pdf"
          >
            Tu dispositivo no puede visualizar los PDF, da click aquí para
            descargarlo
          </a>
        </object>
      </div>
    </Fragment>
  );
};

export default Estatuto;
