import React, { Fragment } from "react";
import LoaderBlog from "../blog/LoaderBlog";
import "../blog/style.css";
import BotonFlotante from "../components/BotonFlotante";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function Single() {
  const [post, setPost] = useState([]);
  const [recuperado, setRecuperado] = useState(false);

  const params = useParams();
  function mostrarTabla() {
    return (
      <section class="p-top-80 p-bottom-80">

          {post.registros.map((publicaciones) => {
            return (
              <Fragment>
                <BotonFlotante />
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <object
                    data={publicaciones.thumb}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <br />
                    <a
                      href={publicaciones.thumb}
                      id="enlaceDescargarPdf"
                      download="estatuto.pdf"
                    >
                      Tu dispositivo no puede visualizar los PDF, da click aquí
                      para descargarlo
                    </a>
                  </object>
                </div>
              </Fragment>
            );
          })}

      </section>
    );
  }

  useEffect(() => {
    fetch(`https://service.aspuchoco.org/sentencias.php?id=${params.id}`)
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        setPost(post);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <center>
        <div class="col-sm-8">
          <div class="postSingle">
            <div class="postMedia">
              <div class="postMeta clearfix">
                <div class="postMeta-info">
                  <span class="metaAuthor">
                    <i class="fa fa-user"></i>
                  </span>

                  <div class="postMeta-date">
                    <span class="metaDate">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
                <div class="postTitle">
                  <div class="divider-small"></div>
                </div>
                <LoaderBlog />;
              </div>
            </div>
          </div>
        </div>
      </center>
    );
}

export default Single;
