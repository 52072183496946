import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import MensajeExito from "./MensajeExito";
import Loader from "./Loader";

const COntacto = () => {
  const [loader, setloader] = useState(false);
  const [mensajesForm, SetMensajes] = useState("");
  const [formularo, setMormulario] = useState(0);
  const handleSetForm = () => {
    setMormulario(0);
    SetMensajes("");
  };

  const [values, setValues] = useState({
    nombre: "",
    email: "",
    telefono: "",
    asunto: "",
    mensaje: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleForm = (event) => {
    event.preventDefault();
    SetMensajes("");
    //validar formulario
    if (
      values.nombre === "" ||
      values.asunto === "" ||
      values.email === "" ||
      values.telefono === "" ||
      values.mensaje === ""
    ) {
      SetMensajes("Hay campos vacios.");
    } else {
      const emailRegex =
        /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      const regex = /^[a-zA-Z\s]+$/;

      const regexTe = /^[0-9]+$/;
      if (!emailRegex.test(values.email)) {
        SetMensajes("El email es incorecto.");
      } else if (!regex.test(values.nombre)) {
        SetMensajes("El nombre tiene caracteres invalidos.");
      } else if (!regexTe.test(values.telefono)) {
        SetMensajes("Numero de telefono invalidos.");
      } else if (values.telefono.length > 10) {
        SetMensajes("Longitud del numero de telefono invalidos.");
      } else if (values.telefono.length < 10) {
        SetMensajes("Longitud del numero de telefono invalidos.");
      } else {
        setloader(true);
        fetch(
          `https://service.aspuchoco.org/mail.php?nombre=${values.nombre}&email=${values.email}&telefono=${values.telefono}&asunto=${values.asunto}&mensaje=${values.mensaje}`
        )
          .then((response) => {
            console.log();
            return response.json();
          })
          .then((mail) => {
            SetMensajes("Mensaje enviado...");
            setloader(false);
            setMormulario(1);
          });
      }
    }
  };
  return (
    <section className="contact_section layout_padding">
      <div className="container">
        <div className="d-flex flex-column align-items-end">
          <div className="custom_heading-container">
            <hr />
            <h2>Contacto</h2>
          </div>
        </div>
        <Fragment>
          {loader === true ? <Loader /> : null}
          <MensajeExito validacion={mensajesForm} />
        </Fragment>
        <div className="layout_padding-top layout_padding2-bottom">
          <div className="row">
            <div className="col-md-7">
              {formularo === 0 ? (
                <form action="" onSubmit={handleForm}>
                  <div className="contact_form-container">
                    <div>
                      <div>
                        <input
                          type="text"
                          name="nombre"
                          placeholder="Nombre"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="email"
                          name="email"
                          placeholder="E-mail"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="telefono"
                          placeholder="Telefono"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          name="asunto"
                          placeholder="Asunto"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="">
                        <input
                          type="mensaje"
                          name="mensaje"
                          placeholder="Mensaje"
                          className="message_input"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mt-5">
                        <button type="submit">Enviar Mensaje</button>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="mt-5">
                  <Link
                    to="/contacto"
                    className="btn btn-success"
                    onClick={handleSetForm}
                  >
                    Enviar otro mensaje
                  </Link>
                </div>
              )}
            </div>
            <div className="col-md-5">
              <div className="map-box">
                <div id="map">
                  <div className="map-responsive">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d992.554759173861!2d-76.646262!3d5.681449!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e488f6125c9c531%3A0x7a3ceb5e9e124dfc!2zQ3JhLiAyMiAjMTktMSwgUXVpYmTDsywgQ2hvY8Oz!5e0!3m2!1ses!2sco!4v1701745594093!5m2!1ses!2sco"
                      width="600"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_items">
          <Link to={""}>
            <div className="item ">
              <div className="img-box box-2"></div>
              <div className="detail-box">
                <p>+57 (320) 672-882</p>
              </div>
            </div>
          </Link>
          <Link to={""}>
            <div className="item ">
              <div className="img-box box-3"></div>
              <div className="detail-box">
                <p>aaspuch@gmail.com</p>
              </div>
            </div>
          </Link>
          <Link to={""}>
            <div className="item ">
              <div className="img-box box-1"></div>
              <div className="detail-box">
                <p>
                  Cra 22 No. 18B – 10 Barrio el Jardín, bloque 2, piso 2,
                  oficina 207.
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default COntacto;
