import React, { Fragment } from 'react'
import Header from '../Header';
import History from './History';



const HistoryPage = () => {
    return (  
        <div>
        <Fragment>
            <Header/>
            <History/>
        </Fragment>
        </div>
    );
}
 
export default HistoryPage;