import React, { Fragment } from "react";
import Header from "../Header";
import Simgle from "./Simgle";

const SimglePage = () => {
  return (
    <Fragment>
      <Header />
      <div>
        <p></p>
      </div>
      <Simgle />
    </Fragment>
  );
};

export default SimglePage;
