import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./components/home/Home";
import SimglePage from "./components/blog/SimglePage";

import EstatutoPage from "./components/acerca/EstatutoPage";
import AcercaPage from "./components/acerca/AcercaPage";
import NosotrosPage from "./components/acerca/NosotrosPage";
import LeyesPage from "./components/acerca/LeyesPage";
import SentenciasPage from "./components/acerca/SentenciasPage";
import ViewPdfPage from "./components/acerca/ViewPdfPage";
import CircularPage from "./components/blog/CircularPage";
import ContactoPage from "./components/contact/ContactoPage";
import BlogPage from "./components/blog/BlogPage";
import EventoPage from "./components/blog/EventoPage";
import HistoryPage from "./components/acerca/HistoryPage";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/normatividad" element={<LeyesPage />}></Route>
        <Route
          path="/normatividad/sentencias"
          element={<SentenciasPage />}
        ></Route>
        <Route
          path="/normatividad/sentencias/:id"
          element={<ViewPdfPage />}
        ></Route>
        <Route path="/blog" element={<BlogPage />}></Route>
        <Route path="/contacto" element={<ContactoPage />}></Route>
        <Route path="/blog/:id/:nombre" element={<SimglePage />}></Route>
        <Route path="/acerca" element={<AcercaPage />}></Route>
        <Route path="/evento/:id/:nombre" element={<EventoPage />}></Route>
        <Route path="/acerca/historia" element={<HistoryPage />}></Route>
        <Route path="/acerca/estatuto" element={<EstatutoPage />}></Route>
        <Route path="/acerca/nosotros" element={<NosotrosPage />}></Route>
        <Route path="/circular/:id/:nombre" element={<CircularPage />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
