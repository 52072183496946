import { useState, useEffect } from "react";
import "./EventoPost.css";
import { Link } from "react-router-dom";

function EventoPost(idPost) {
  var id = idPost.idPost;
  const [articulos, setArticulos] = useState([]);
  const [recuperado, setRecuperado] = useState(false);

  function mostrarTabla() {
    return (
      <div>
        <div class="post">
          {articulos.registros.map((art) => {
            return (
              <div>
                <center>
                  <h1 className="post-title">{art.titulo}</h1>
                </center>

                <p className="post-content">{art.extracto}</p>
                <img src={art.imgPost} alt="" />
                <p>
                  <span>{art.fecha}</span>
                </p>
                {art.texto !== null ? <p>{art.texto}</p> : null}

                <center>
                  <Link to="/blog" className="btn btn-secondary">
                    Ver mas post
                  </Link>
                  <div>
                    <p></p>
                  </div>
                </center>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  useEffect(() => {
    fetch(`https://service.aspuchoco.org/single.php?id_post_evento=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((articulos) => {
        setArticulos(articulos);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <center>
        <div>recuperando datos...</div>
      </center>
    );
}

export default EventoPost;
