import React from "react";
import LoaderBlog from "../blog/LoaderBlog";
import "../blog/style.css";
import "./Style.css";
import "./Tabla.css";

import { useState, useEffect } from "react";

function History() {
  const [post, setPost] = useState([]);
  const [recuperado, setRecuperado] = useState(false);
  function mostrarTabla() {
    return (
      <section class="p-top-80 p-bottom-80">
        <center>
          {post.registros.map((publicaciones) => {
            return (
              <article>
                <h2>
                  <strong>{publicaciones.nombre}</strong>
                </h2>

                <center>
                  <img src={publicaciones.thumb} width="60%" />
                </center>
                <h1> </h1>
                <h3>
                  HISTORIA DEL NACIMIENTO DE ASPU - SECCIONAL UNIVERSIDAD
                  TECNOLOÓGICA DEL CHOCÓ
                </h3>
                <h1> </h1>
                <p className="text-align-justify">{publicaciones.parrafo1}</p>
                <p className="text-align-justify">{publicaciones.parrafo2}</p>
                <p className="text-align-justify">{publicaciones.parrafo3}</p>
                <p className="text-align-justify">{publicaciones.parrafo4}</p>
                <p className="text-align-justify">{publicaciones.parrafo5}</p>
                <p className="text-align-justify">{publicaciones.parrafo6}</p>

                <l1 align="left">
                  1. La inscripción en el escalafón Docente a nivel nacional.
                </l1>
                <l1 align="left">
                  2. El reconocimiento que el gobierno le hiciera a nuestra
                  institución como Universidad.
                </l1>
                <l1 align="left">
                  3. La construcción de la Ciudadela Universitaria.
                </l1>
                <l1 align="left">
                  4. La profesionalización de los programas tecnológicos.
                </l1>
                <l1 align="left">
                  5. El reconocimiento de los grupos de investigación en
                  COLCIENCIAS.
                </l1>
                <l1 align="left">
                  6. Las diferentes negociaciones colectivas, mediante las
                  cuales se logró la vinculación de los profesores ocasionales
                  por periodos un poco mayor a once meses, aspecto que se
                  destaca como piloto a nivel nacional.
                </l1>
                <l1 align="left">7. El Seguro de Vida Colectivo.</l1>
                <l1 align="left">8. La Formalización Profesoral Docente.</l1>

                <div id="main-container">
                  <h1>FUNDADORES</h1>
                  <table>
                    <tr>
                      <td>Carlos Calderón Mosquera</td>
                      <td>Roberto Morales Parra</td>
                      <td>Álvaro Giraldo Gómez</td>
                    </tr>
                    <tr>
                      <td>Francisco Moreno Mosquera</td>
                      <td>Wilson Moreno Mosquera</td>
                      <td>Carlos Enrique Ayala</td>
                    </tr>
                    <tr>
                      <td>William García Rodríguez</td>
                      <td>Deicy Restrepo Pineda</td>
                      <td>Betty de la Hoz</td>
                    </tr>
                    <tr>
                      <td>Juan Moreno Terán</td>
                      <td>Ismain Palacios C.</td>
                      <td>Adiela Larne Valencia</td>
                    </tr>
                    <tr>
                      <td>Flaminio Londoño</td>
                      <td>Heladio Ibargüen Palomino</td>
                      <td>Argelia Londoño Vélez</td>
                    </tr>
                    <tr>
                      <td>Julio Halaby Cordoba</td>
                      <td>Miguel Angel Medina Rivas</td>
                      <td>Efraín Moreno Rodríguez</td>
                    </tr>
                  </table>
                </div>
              </article>
            );
          })}
        </center>
      </section>
    );
  }

  useEffect(() => {
    fetch(`https://service.aspuchoco.org/historia.php?id=1`)
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        setPost(post);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <center>
        <div class="col-sm-8">
          <div class="postSingle">
            <div class="postMedia">
              <div class="postMeta clearfix">
                <div class="postMeta-info">
                  <span class="metaAuthor">
                    <i class="fa fa-user"></i>
                  </span>

                  <div class="postMeta-date">
                    <span class="metaDate">
                      <i class="fa fa-calendar"></i>LoaderBlog
                    </span>
                  </div>
                </div>

                <div class="postTitle">
                  <div class="divider-small"></div>
                </div>
                <LoaderBlog />
              </div>
            </div>
          </div>
        </div>
      </center>
    );
}

export default History;
