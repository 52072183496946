import React, { Fragment } from "react";
import Header from "../Header";
import Sentecias from "./Sentecias";

const SentenciasPage = () => {
  return (
    <Fragment>
      <Header />
      <Sentecias />
    </Fragment>
  );
};

export default SentenciasPage;
