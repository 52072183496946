import React, { Fragment } from 'react'
import Header from '../Header';
import Leyes from './Leyes';


const LeyesPage = () => {
    return (  
        <div>
        <Fragment>
            <Header/>
            <Leyes/>
        </Fragment>
        </div>
    );
}
 
export default LeyesPage;