import React from "react";
import { Link } from "react-router-dom";
import Imagenes from "../../assets/Imagenes";

const Acerca = () => {
  return (
    <section className="service_section layout_padding">

      <div className="container">
        <div className="service_container layout_padding2">
          <Link to={"/acerca/historia"}>
            <div className="box">
              <div clclassNameass="img-box">
              <img src={Imagenes.historia} alt="" className="img-1" width="90%" />
                <img src={Imagenes.historia} alt="" className="img-2" width="100%" />
              </div>
              <div class="name">
                <h6>HISTORIA</h6>
              </div>
            </div>
          </Link>
          <Link to={"/acerca/estatuto"}>
            <div className="box">
              <div className="img-box">
                <img src={Imagenes.normas} alt="" className="img-1" width="90%" />
                <img src={Imagenes.normas} alt="" className="img-2" width="110%" />
              </div>
              <div className="name">
                <h6>Estatuto</h6>
              </div>
            </div>
          </Link>
          <Link to={"/acerca/nosotros"}>
            <div className="box">
              <div className="img-box">
              <img src={Imagenes.about} alt="" className="img-1" width="90%" />
                <img src={Imagenes.about} alt="" className="img-2" width="110%" />
              </div>
              <div className="name">
                <h6>Acerca de nosotros</h6>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="read-btn">
        <Link to={"/"}>Volver a inicio...</Link>
      </div>
    </section>
  );
};

export default Acerca;
