import React from "react";
import { Fragment } from "react";
import Slinder from "./Slinder";
import Post from "./Post";
import Header from "../Header";

const Home = () => {
  return (
    <Fragment>
      <Header />
      <Slinder />
      <Post />
    </Fragment>
  );
};

export default Home;
