import React from "react";

const MensajeExito = ({ validacion }) => {
  return (
    <div>
      {validacion !== "" ? (
        <div
          class="toast align-items-center text-bg-success border-0 fade show"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="d-flex">
            <div class="toast-body">
             <center><h5>{validacion}</h5></center> 
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MensajeExito;
