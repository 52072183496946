import React from "react";

const Nosotros = () => {
  return (
    <section class="p-top-80 p-bottom-80">
      <center>
        <article>
          <h1> </h1>
          <h2>
            <strong>ACERCA DE NOSOTROS</strong>
          </h2>

          <h1> </h1>
          <h3>MISIÓN</h3>
          <h1> </h1>
          <p className="text-align-justify">
            Defender dentro del marco jurídico laboral, los principios de
            concertación laboral, diálogo respetuoso y amigable composición, los
            derechos laborales, prestacionales, políticos y de participación
            democrática de los docentes de la Universidad Tecnológica del Chocó.
          </p>
          <h1> </h1>
          <h3>VISIÓN</h3>
          <h1> </h1>
          <p className="text-align-justify">
            La Asociación Sindical de Profesores Universitarios ASPU –
            Universidad Tecnológica del Chocó, en el año 2020 será reconocida
            como la principal Organización Sindical de Educación Superior en el
            departamento del Chocó y entre las cinco (5) primeras a nivel,
            nacional por ejercer la defensa ardua pero respetuosa a los derechos
            laborales, salariales, prestacionales, sindicales, de participación
            democrática, propendiendo por el Trabajo Digno y Decente de los
            docentes, de la comunidad universitaria y la sociedad chocoana en
            general.
          </p>
          <h1> </h1>
          <h3>OBJETIVOS</h3>
          <h1> </h1>
          <h1> </h1>
          <h5 align="left">GENERAL</h5>
          <h1> </h1>
          <l1 align="left">
            1. Defender efectivamente los derechos de sus afiliados y afiliadas,
            emprendiendo todo tipo de acciones, actividades, planes, estrategias
            y alternativas para la solución de los mismos.
          </l1>
          <h1> </h1>
          <h5 align="left">ESPECÍFICOS</h5>
          <h1> </h1>
          <l1 align="left">
            1. Defender el derecho a una educación pública, gratuita y de
            calidad.
          </l1>
          <l1 align="left">
            2. Defender los derechos de sus afiliados y afiliadas, respecto de
            sus salarios, prestaciones sociales, Condiciones Económicas y de
            Trabajo, Condiciones Académicas, de Investigación, de Extensión,
            Proyección Social y de Bienestar Social, Sistema de Salud,
            Protección y Prevención de Accidentes.
          </l1>
          <l1 align="left">
            3. Dentro del marco de respeto de los derechos humanos, procurar el
            acercamiento entre trabajadores y empleadores, para el
            fortalecimiento de la Institución.
          </l1>
          <h1> </h1>
          <h3>
            JUNTA DIRECTIVA ASPU – SECCIONAL UNIVERSIDAD TECNOLÓGICA DEL CHOCÓ
          </h3>
          <h1> </h1>
          <l1 align="left">
            <strong>PRESIDENTE</strong>: JORGE ENRIQUE PEREA GONZÁLEZ.
          </l1>
          <h1> </h1>
          <l1 align="left">
            <strong>VICEPRESIDENTE</strong>: EDWIN ETTIEL ARAGÓN LOZANO.
          </l1>
          <h1> </h1>
          <l1 align="left">
            <strong>TESORERO(A)</strong>: JESÚS ANTONIO CUESTA COPETE.
          </l1>
          <h1> </h1>
          <l1 align="left">
            <strong>SECRETARIA</strong>: NANCY GARRIDO PALOMEQUE.
          </l1>
          <h1> </h1>
          <l1 align="left">
            <strong>FISCAL</strong>: WILLIAM VALENCIA MARTINEZ.
          </l1>
          <h1> </h1>
          <l1 align="left">
            <strong>VOCALE</strong>:<li>ROSA EMILIA MOSQUERA MAYO.</li>
            <li>LUZ MILA QUINTO MOYA.</li>
            <li>EDINSON MORENO TAMAYO.</li>
            <li>VICTOR RAFAEL FRANCISCO VALENCIA ABADIA.</li>
            <li>ARCADIO VELASQUEZ MURILLO.</li>
          </l1>
          <h1> </h1>
        </article>
      </center>
    </section>
  );
};

export default Nosotros;
