import React, { Fragment } from 'react'
import Blog from './Blog';
import Header from '../Header';


const BlogPage = () => {
    return ( 
        <Fragment>
            <Header/>
            <Blog/>
        </Fragment>
     );
}
 
export default BlogPage;