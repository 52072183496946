import React, { Fragment } from 'react'
import Header from '../Header';
import ViewPdf from './ViewPdf'


const ViewPdfPage = () => {
    return ( 
        <Fragment>
            <Header/>
            <ViewPdf/>
        </Fragment>
     );
}
 
export default ViewPdfPage;