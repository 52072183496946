import React, { Fragment } from "react";
import Header from "../Header";
import Nosotros from "./Nosotros";

const NosotrosPage = () => {
  return (
    <Fragment>
      <Header />
      <Nosotros />
    </Fragment>
  );
};

export default NosotrosPage;
