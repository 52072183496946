import React from "react";
import LoaderBlog from "./LoaderBlog";
import "./style.css";
import "./Gallery.css";
import Modal from "react-modal";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EventoPost from "./EventoPost";

function Evento() {
  const [post, setPost] = useState([]);
  const [recuperado, setRecuperado] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const params = useParams();
  const idPost = params.id;

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  function mostrarTabla() {
    return (
      <section class="p-top-80 p-bottom-80">
        <EventoPost idPost={idPost} />
        <center>
          <div class="gallery">
            {post.registros.map((publicaciones) => {
              return (
                <div class="image">
                  <img
                    src={publicaciones.thumsPost}
                    alt=""
                    onClick={() => openModal(publicaciones.thumsPost)}
                  />
                </div>
              );
            })}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Imagen en grande"
            >
              {selectedImage && (
                <div>
                  <img src={selectedImage} alt="Imagen en grande" />
                  <button onClick={closeModal}>Cerrar</button>
                </div>
              )}
            </Modal>
          </div>
        </center>
      </section>
    );
  }

  useEffect(() => {
    fetch(`https://service.aspuchoco.org/single.php?id_post=${params.id}`)
      .then((response) => {
        return response.json();
      })
      .then((post) => {
        setPost(post);
        setRecuperado(true);
      });
  }, []);

  if (recuperado) return mostrarTabla();
  else
    return (
      <center>
        <div class="col-sm-8">
          <div class="postSingle">
            <div class="postMedia">
              <div class="postMeta clearfix">
                <div class="postMeta-info">
                  <span class="metaAuthor">
                    <i class="fa fa-user"></i>
                  </span>

                  <div class="postMeta-date">
                    <span class="metaDate">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
                <div class="postTitle">
                  <div class="divider-small"></div>
                </div>
                <LoaderBlog />;
              </div>
            </div>
          </div>
        </div>
      </center>
    );
}

export default Evento;
