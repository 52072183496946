import React from "react";
import "./LoaderBlog.css";

const LoaderBlog = () => {
  return (
    <div className="loader3">
      <div className="circle1"></div>
      <div className="circle1"></div>
      <div className="circle1"></div>
      <div className="circle1"></div>
      <div className="circle1"></div>
    </div>
  );
};

export default LoaderBlog;
