import React, { Fragment } from "react";
import Header from "../Header";
import Circular from "./Circular";

const CircularPage = () => {
  return (
    <Fragment>
      <Header />
      <Circular />
    </Fragment>
  );
};

export default CircularPage;
