import React, { Fragment } from 'react'
import Acerca from './Acerca';
import Header from '../Header';


const AcercaPage = () => {
    return ( 
        <Fragment>
            <Header/>
            <Acerca/>
        </Fragment>
     );
}
 
export default AcercaPage;