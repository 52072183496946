import aspuch from './img/aspu.png'
import telefono from './img/call.png'
import slinder from './img/seis.jpg'
import fb from './img/fb.png'
import tw from './img/twitter.png'
import ins from './img/insta.png'
import you from './img/youtube.png'
import normas from './img/normas.png'
import historia from './img/historia.png'
import about from './img/about.png'
import whatsapp from './img/whatsapp.png'
import cut from './img/cut.png'
import ie from './img/ie.png'

export default {
    'aspuch' : aspuch,
    'telefono' : telefono,
    'slinder': slinder,
    'fb' : fb,
    'tw' : tw,
    'you': you,
    'ins': ins,
    'normas' : normas,
    'historia': historia,
    'about' : about,
    'whatsapp': whatsapp,
    'cut' : cut,
    'ie': ie,
}