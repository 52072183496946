import React from "react";

const Footer = () => {
  const handleClickhd = () => {
    window.open("https://www.hadinsohinestroza.com", "_blank");
  };
  var y = new Date().getFullYear();
  return (
    <section className="container-fluid footer_section">
      <center>
        <div>
          &copy; {y} All Rights Reserved By
          <p onClick={handleClickhd}>
            {" "}
            HADINSO HINESTROZA, JORDAN COSSIO & RAFAEL SANDOVAL
          </p>
        </div>
      </center>
    </section>
  );
};

export default Footer;
